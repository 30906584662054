@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  font-family: "Inter", sans-serif;
}

.searchContainer {
  margin: 20px auto;
  background: #f6f6f6;
  border-radius: 1px;
  position: relative;
  display: flex;
  align-items: center;
}

.searchContainer img {
  position: absolute;
  left: 34%;
  top: 50%;
  transform: translate(-34%, -50%);
  width: 18px;
}

.searchBox {
  width: 100%;
  padding: 18px;
  border: none;
  border-radius: 1px;
  font-size: 16px;
  outline: none;
  background-color: #f6f6f6;
}

.searchBox::placeholder {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 200;
  letter-spacing: 2px;
  text-align: center;
}

.profileSection {
  background-color: #f6f6f6;
  padding: 65px 0 65px 35px;
  overflow: hidden;
}

.profileSection .profileLeft .profileMenu {
  border: 2px solid #000;
  margin-top: 25px;
  text-align: left;
}

.profileSection .profileLeft .profileMenuName {
  border-bottom: 2px solid #000;
}

.profileSection .profileLeft .profileMenuName a {
  text-decoration: none;
}

.profileSection .profileLeft .profileMenuName:last-child {
  border-bottom: none;
}

.profileSection .profileLeft .profileMenuName h4 {
  font-weight: 800;
  font-size: 18px;
  color: #2e2e2e;
  margin: 20px 0 20px 20px;
}

.profileSection .profileInfo {
  background-color: #fff;
  padding: 32px 54px;
  margin-bottom: 34px;
}

.profileSection .profileInfo h5 {
  font-size: 28px;
  font-weight: 800;
  margin-bottom: 15px;
}

.profileSection .profileInfo h1 {
  font-size: 45px;
  font-weight: 900;
  margin: 0;
  margin-bottom: 20px;
}

.profileSection .profileInfo .companyName {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.profileSection .profileInfo .companyName span {
  font-size: 22px;
  font-style: italic;
  margin: 0;
}

.profileSection .profileInfo .companyName h3 {
  font-size: 22px;
  font-weight: bold;
  margin: 0;
}

.profileSection .profileInfo .directorName {
  margin-bottom: 24px;
}

.profileSection .profileInfo .directorName h3 {
  font-size: 22px;
  font-weight: bold;
  margin: 0;
}

.profileSection .profileInfo .directorName span {
  font-size: 22px;
  font-style: italic;
}

.profileSection .profileInfo .companyLocation {
  display: flex;
  justify-content: space-between;
  color: #787878;
  margin-bottom: 25px;
}

.profileSection .profileInfo .companyLocation .location {
  display: flex;
  align-items: center;
}

.profileSection .profileInfo .companyLocation h4 {
  font-size: 18px;
  margin: 0;
  font-weight: 700;
}

.profileSection .profileInfo .companyLocation span {
  font-size: 18px;
  margin-left: 5px;
}

.profileInfo .profileContent p {
  font-size: 18px;
  margin-bottom: 20px;
}

.profileInfo .profileContent .timeline {
  background-color: #f6f6f6;
  padding: 15px;
}

.profileInfo .profileContent .timeline .timelineYear {
  display: flex;
  gap: 13px;
  align-items: center;
  margin-bottom: 10px;
}

.profileInfo .profileContent .timeline .timelineYear span {
  font-size: 16px;
  font-weight: 600;
}

.profileInfo .profileContent .yearWiseContent {
  height: 540px;
  overflow-y: auto;
}

.profileInfo .profileContent .timeLineContent {
  margin-bottom: 32px;
}

.profileInfo .profileContent .timeLineContent h3 {
  font-size: 32px;
  font-weight: 800;
}

.profileInfo .profileContent .timeLineContent span {
  color: #8f8f8f;
  font-size: 18px;
}

.profileInfo .profileContent .award {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  margin-top: 13px;
}

.profileInfo .profileContent .award .awardText {
  max-width: 500px;
}

.profileInfo .profileContent .award .awardText h5 {
  font-size: 18px;
  font-weight: 800;
  margin: 0;
}

.profileInfo .profileContent .award .awardText span {
  font-size: 16px;
  margin: 0;
}

.profileInfo .profileContent .news h6 {
  color: #2c7279;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 2px solid #2c7279;
  cursor: pointer;
}

.profileSection .profileCompany {
  background-color: #fff;
  padding: 40px 20px;
  text-align: center;
  height: 100%;
}

.profileSection .profileCompany .relatedCompany {
  border-bottom: 2px solid #000;
}

.profileSection .profileCompany .relatedCompany h3 {
  font-size: 26px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 8px;
}

.profileSection .profileCompany .companyLogos {
  border-bottom: 2px solid #000;
}

.profileSection .profileCompany .companyLogos img {
  margin: 15px 0;
}

.profileContent .galleryGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
}

.galleryImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.galleryImage:hover {
  transform: scale(1.05);
}

.profileInfo .videoSlider img {
  margin-bottom: 16px;
}

.profileInfo .videoSlider h4 {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 800;
  margin: 0;
}

.profileInfo .videoSlider span {
  font-size: 18px;
}

.profileInfo .videoSlider p {
  margin-top: 15px;
  font-style: italic;
  font-weight: 300;
}

/* ARTICLES STYLES */

.articleBox {
  margin-top: 33px;
}

.articleBox .articleSlider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
}

.articleBox .articleSlider h4 {
  font-size: 18px;
  font-weight: 800;
  max-width: 250px;
}

.articleBox span {
  color: #8f8f8f;
  font-size: 16px;
  margin: 0;
  font-weight: 400;
}

.articleBox p {
  margin-top: 30px;
  font-style: italic;
  font-weight: 300;
}

.profileInfo .articleSection {
  position: relative;
}

.arrowImg {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.left {
  left: -35px;
}

.right {
  right: -35px;
}

.profileSection .profileCompany .similarProfile {
  margin-top: 88px;
}

.profileSection .profileCompany .similarProfile .companyNames {
  border-bottom: 2px solid #000;
}

.profileSection .profileCompany .similarProfile .companyNames .companyRow {
  margin: 20px 0;
}

.profileSection .profileCompany .similarProfile .companyNames h3 {
  font-size: 18px;
  margin: 0;
  font-weight: 800;
}

.profileSection .profileCompany .similarProfile .companyNames span {
  font-size: 18px;
  margin: 0;
  font-style: italic;
}

.profileSection .profileCompany .similarProfile .companyNames h4 {
  font-size: 18px;
  margin: 0;
  font-weight: 700;
}

.profileSection .profileCompany .similarProfile .seeMore {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  gap: 12px;
  cursor: pointer;
}

.profileSection .profileCompany .similarProfile .seeMore h3 {
  font-size: 24px;
  font-weight: 800;
}

.profileInfo .socialIcon {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 30px;
}

@media (max-width: 767px) {
  .profileSection {
    padding: 0;
  }

  .profileSection .profileInfo {
    padding: 20px;
    margin-top: 20px;
  }

  .profileSection .profileInfo h1 {
    font-size: 40px;
    text-align: center;
  }

  .profileSection .profileInfo .companyName {
    display: grid;
    gap: 20px;
  }

  .profileSection .profileInfo .companyLocation {
    display: grid;
    gap: 5px;
  }

  .profileInfo .profileContent .yearWiseContent {
    margin-top: 30px;
  }
}

@media (max-width: 991px) {
  .profileSection {
    padding: 0;
  }

  .profileSection .profileInfo {
    padding: 20px;
    margin-top: 20px;
  }

  .profileSection .profileCompany {
    text-align: left;
  }
}
