.sharedSection {
    background-color: #f3f2f0;
    padding: 70px 0;
  }
  
  .defaultBackground {
    background-color: #f3f2f0;
  }
  
  .lightBackground {
    background-color: #ffffff;
  }

  
  .sectionHeading {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 0.7rem;
  }
  
  .sectionDescription {
    line-height: 30px;
    margin-bottom: 1.5rem;
    max-width: 550px;
    text-align: justify;
    font-size: 15px;
  }
  
  .tagsContainer {
    display: grid;
    /* gap: 0.8rem; */
  }
  
  .tagButton {
    border: 1px solid #000;
    font-weight: 600;
    border-radius: 24px;
    padding: 0.8rem 1rem;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    color: #000000bf;
    font-family: 'Roboto', sans-serif;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
    margin-right: 12px;
    min-width: 10rem;
  
  }

  .tagInnerCont button:last-child {
    margin-right: 0;
  }
  
  .tagButton:hover {
    border: 1px solid #3696a0;
    color: #3696a0;
  }
  


  @media screen and (max-width: 767px) {
    .sharedSection{
      padding: 30px 0;
    }
  }
  