/* HEADER STYLES */
.headerFixed {
  background-color: #fff;
  padding: 20px 40px;
  height: 80px;
  color: #ffffff;
  box-sizing: border-box;
  top: -100px;

  -webkit-transition: top 0.3s;
  transition: top 0.3s;
}

.headerFixed .headerLimiter {
  text-align: center;
  margin: 0 auto;
}

.headerFixed .headerLimiter .headerLogo {
  width: 200px;
}

/*	The header placeholder. It is displayed when the header is fixed to the top of the
	browser window, in order to prevent the content of the page from jumping up. */

.headerFixed-placeholder {
  height: 80px;
  display: none;
}

/* Logo */

.headerFixed .headerLimiter img {
  float: left;
  font: normal 28px Cookie, Arial, Helvetica, sans-serif;
  line-height: 40px;
  margin: 0;
}

.headerFixed .headerLimiter h1 span {
  color: #5383d3;
}

/* The navigation links */

.headerFixed .headerLimiter a {
  color: #ffffff;
  text-decoration: none;
}

.headerFixed .headerLimiter nav {
  font: 16px Arial, Helvetica, sans-serif;
  line-height: 40px;
  float: right;
}

.headerFixed .headerLimiter nav a {
  display: inline-block;
  padding: 0 18px;
  text-decoration: none;
  color: #000;
  opacity: 0.9;
}

.headerFixed .headerLimiter nav a:hover {
  opacity: 1;
}

.headerFixed .headerLimiter nav a.selected {
  color: #608bd2;
  pointer-events: none;
  opacity: 1;
}

.headerImg {
  width: 28px;
  height: auto;
  user-select: none;
  pointer-events: none;
  -webkit-user-drag: none;
}

.headerButton {
  font-size: 16px;
  line-height: 24px;
  border: none;
}

.joinButton {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  border: none;
  z-index: 1;
  background: transparent;
  color: #000000bf;
}

.signInButton {
  border: 1px solid #3696a0;
  color: #3696a0;
  background-color: transparent;
  font-weight: 700;
  border-radius: 24px;
  padding: 7px 30px;
  text-align: center;
  font-size: 16px;
  z-index: 1;
  line-height: 24px;
  cursor: pointer !important;
  transition: all 0.2s;
  align-items: center;
}

body.fixed .headerFixed {
  padding: 10px 40px;
  height: 50px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

body.fixed .headerFixed-placeholder {
  display: block;
}

body.fixed .headerFixed .headerLimiter h1 {
  font-size: 24px;
  line-height: 30px;
}

body.fixed .headerFixed .headerLimiter nav {
  line-height: 28px;
  font-size: 13px;
}

/* Making the header responsive */

@media all and (max-width: 600px) {
  .headerFixed {
    padding: 20px 0;
    height: 75px;
  }

  .headerFixed .headerLimiter h1 {
    float: none;
    margin: -8px 0 10px;
    text-align: center;
    font-size: 24px;
    line-height: 1;
  }

  .headerFixed .headerLimiter nav {
    line-height: 1;
    float: none;
  }

  .headerFixed .headerLimiter nav a {
    font-size: 13px;
  }

  body.fixed .headerFixed {
    display: none;
  }
}

/* FOOTER STYLES */
.footerSection {
  background-color: #fff;
  overflow: hidden;
  padding: 30px 40px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.footerSection .logoContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footerSection .logoContent .footerLogo {
  width: 300px;
  max-width: 400px;
}
.emailBox {
  padding: 10px;
  border: none;
  border-radius: 14px;
  font-size: 16px;
  outline: none;
  background-color: #f6f6f6;
}

.emailBox::placeholder {
  letter-spacing: 2px;
}

.submitBtn {
  border: none;
  background-color: #2c7279;
  padding: 10px 20px;
  border-radius: 14px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin-left: -10px;
}

.footerSection .logoContent span {
  font-size: 18px;
  letter-spacing: 2px;
  margin-right: 10px;
}

.footerSection .footerMenu {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.footerSection .footerMenu h3 {
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 18x;
}

.footerSection .footerMenu h5 {
  color: #2e2e2e;
}

.footerSection .footerMenu a {
  font-size: 16px;
  color: #2e2e2e;
  text-decoration: none;
  font-weight: 600;
}

.copyRight {
  padding: 12px 25px;
}

.copyRight .copyRightInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyRight span {
  margin-left: 15px;
  font-size: 14px;
  font-weight: 500;
}
.socialMenu {
  display: flex;
}

.socialIcon {
  display: flex;
  gap: 30px;
  margin-left: 50px;
}

@media (max-width: 767px) {
  .footerSection {
    padding: 15px;
  }

  .footerSection .footerMenu {
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 30px;
  }

  .footerSection .logoContent .footerLogo {
    width: 300px;
  }

  .emailBox {
    margin: 10px 0;
  }

  .socialMenu {
    display: grid;
  }

  .copyRight .copyRightImage {
    display: grid;
    gap: 15px;
  }
}

@media (max-width: 991px) {
  .footerSection {
    padding: 15px;
  }

  .footerSection .footerMenu {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }

  .footerSection .logoContent .footerLogo {
    width: 400px;
    margin: 0 auto;
  }
  .footerSection .logoContent {
    display: grid;
    gap: 15px;
    margin: 0 auto;
  }

  .copyRight {
    padding: 15px;
  }

  .socialMenu {
    display: flex;
    gap: 20px;
  }

  .copyRight .copyRightInfo {
    display: grid;
    gap: 20px;
  }

  .copyRight span {
    margin-left: 0;
    margin-bottom: 10px;
  }

  .copyRightImage img {
    margin-right: 10px;
  }

  .socialIcon {
    margin-left: 0;
  }
}
