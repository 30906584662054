.heroSection {
  position: relative;
  margin-top: 30px;
  overflow: hidden;
}

.heroContentImg {
  margin-top: 20px;
}

.heroContent {
  text-align: center;
  z-index: 1;
}

.logo {
  max-width: 350px;
  user-select: none;
  pointer-events: none;
  -webkit-user-drag: none;
}

.tagline {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 30px;
  color: #000;
  font-family: 'Source Serif Pro', serif;
}

.heroDescription {
  font-size: 14px;
  color: #000;
  margin-bottom: 0.5rem;
  line-height: 1.6;
  font-style: italic;
  font-weight: 400;
}

.authButtons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 400px;
  margin: 2rem auto 1rem;
}

.googleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #fff;
  background-color: #3696a0;
  font-weight: 400;
  border-radius: 40px;
  padding: 3px 30px;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  transition: all 0.2s;
}

.googleButton img {
  margin-right: 10px;
  user-select: none;
  pointer-events: none;
  -webkit-user-drag: none;
}

.emailButton {
  border: 1px solid #000;
  background: transparent;
  font-weight: 600;
  border-radius: 40px;
  padding: 8px 30px;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  transition: all 0.2s;
}

.emailButton:hover {
  background: #f5f5f5;
}

.terms {
  font-size: 11px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 1rem;
  max-width: 400px;
  margin: auto;
}

.terms a{
  color: #3696a0;
  font-weight: 700;
  text-decoration: none;
  line-height: 18px;
  cursor: pointer;
  font-size: 11px;
}

.heroLink{
  color: #3696a0;
  font-weight: 700;
  text-decoration: none;
  line-height: 18px;
  cursor: pointer;
}

.joinNowText {
  color: #000;
}

.heroImageContainer {
  text-align: center;
}

.heroImage {
  width: 100%;
  height: auto;
  user-select: none;
  pointer-events: none;
}

.bgImageHero {
  position: absolute;
  bottom: 0;
}

.bgImageHero img {
  opacity: 0.1;
  width: 100%;
  user-select: none;
  pointer-events: none;
}
.newtopedia{
  font-size: 15px !important;
}