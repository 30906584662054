@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jersey+15&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Source+Serif+4:ital,opsz,wght@0,8..60,200..900;1,8..60,200..900&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");
@font-face {font-family: "Source Serif Pro"; src:url("../fonts/SourceSerifPro-Regular.otf") format("truetype"); font-style: normal;}

body{
  font-family: "Inter", sans-serif !important;
}

body {
  direction: ltr;
  text-align: left;
}

body[dir="rtl"], html[dir="rtl"] body {
  direction: rtl;
  text-align: right;
}

.ltrcls{
  direction: ltr;
  text-align: left;
}