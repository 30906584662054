.empoweringSection {
  padding-top: 40px;
  overflow: hidden;
}

.container {
  position: relative;
  z-index: 1;
}

.empowerContent {
  text-align: left;
  position: relative;
}

.sectionHeading {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.4rem;
  color: #333;
  margin-bottom: 20px;
}

.empowerButton {
  background-color: #3696a0;
  color: #fff;
  font-weight: 600;
  border: none;
  border-radius: 24px;
  padding: 0.6rem 1.7rem;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.empowerButton:hover {
  background-color: #287680;
}

.empoweringImg {
  position: relative;
  margin-top: -30px;
}

.empowerImage {
  width: 100%;
  user-select: none;
  pointer-events: none;
}
